@use '@/styles/utils/mixins.scss' as *;

.relatedArticlesWrapper {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin: 6.25rem 0;
  @include tab() {
    margin: 5rem 0;
  }
  .mediaList {
    margin: auto;
    max-width: 100%;
    @include tab() {
      display: flex;
      flex-direction: column;
      gap: 2rem;
    }

    .swiperSlide {
      align-self: stretch;
      height: auto;
      & > * {
        height: 100%;
        width: 25.875rem;
        @include mob() {
          width: 100%;
        }
      }
    }
    :global {
      .swiper-wrapper {
        padding-bottom: 1.875rem;
        align-items: flex-start;
        .swiper-slide {
          width: auto;
          margin: 0 1.25rem;
          @include mob() {
            margin: 0;
          }
          &:first-child {
            margin-left: 2.5rem;
            @include mob() {
              margin-left: 0;
            }
          }
          &:last-child {
            margin-right: 2.5rem;
            @include mob() {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .swipeWrapper {
    display: flex;
    justify-content: flex-end;

    .swiperIconBtn {
      display: flex;
      align-items: center;
      gap: 1.25rem;
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: $primary;
      font-weight: 500;
      transition: all 0.2s ease-in-out;

      img {
        transition: all 0.2s ease-in-out;
      }

      &:hover,
      &:focus,
      &:active {
        color: $primaryAlt;

        img {
          opacity: 0.5;
        }
      }
    }
  }
}
